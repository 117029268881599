import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_common/auth.service';
import { AuthModel } from 'src/app/_models/auth-model';
import { AuthResult } from 'src/app/_models/auth-result';

@Component({
    selector: 'signin',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit() { }

    loginUser(event) {
        event.preventDefault();

        let model: AuthModel = {
            email: event.target.querySelector('#username').value,
            password: event.target.querySelector('#password').value,
            rememberMe: true
        }

        this.authService.getUserDetails(model)
            .subscribe(
                (response: AuthResult) => {
                    if (response.result) {
                        this.router.navigate(['/chart'])
                    }
                    else {
                        alert("Unauthorized access");
                    }
                },
                error => {
                    console.log(error);
                });
    }
}
