import { Component, OnInit } from '@angular/core';
import { forkJoin, iif, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment-mini';

import { Controller } from '../../_models/controller';
import { Location } from '../../_models/location';
import { OxygenMeasure } from '../../_models/oxygenMeasure';
import { FlowRate } from '../../_models/flowRate';
import { ControllerService } from '../../_common/controller.service';
import { LocationsService } from '../../_common/locations.service';
import { MeasurementsService } from '../../_common/measurements.service';
import { Entity } from 'src/app/_models/entity.interface';
import { ConsumptionService } from 'src/app/_common/consumption.service';
import { Consumption } from 'src/app/_models/consumption';
import { ConsumptionMeasurementsService } from 'src/app/_common/consumption-measurements.service';


@Component({
  selector: 'app-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: ['./chart-wrapper.component.scss'],
})
export class ChartWrapperComponent implements OnInit {

  controllersList: Controller[];
  consumptionsList: Consumption[];
  filteredEntities: Entity[];
  selectedEntity: Entity = new Controller;
  isConsumption: boolean = true;

  locationsList: Location[];
  selectedLocation: string = '';
  selectedLocationName: string;

  oxygenMeasure: OxygenMeasure;
  flowRate: Array<FlowRate>;

  timer: Subscription;
  currentDate: string;
  showError: boolean;

  currentFlowRate: number;
  reserveFlowRate: number;
  skidDrCapacity: number;
  dialPercentValue: number;

  spinnerShow:boolean = false;

  constructor(
    private locationsService: LocationsService,
    private controllerService: ControllerService,
    private consumptionService: ConsumptionService,
    private measurementsService: MeasurementsService,
    private consumptionMeasurementsService: ConsumptionMeasurementsService,

  ) { }

  ngOnInit() {

    this.currentDate = moment().format('DD/MM/YYYY, h:mm a');

    forkJoin([
      this.locationsService.listAsync(),
      this.controllerService.listAsync(),
      this.consumptionService.listAsync()
    ])
      .subscribe(([locations, controllers, consumptions]) => {

        this.controllersList = controllers.filter(c => c.manufactureType === 3);
        this.consumptionsList = consumptions;

        this.locationsList = locations.filter(l =>
          this.controllersList.some(d => d.locationId === l.id)
          || this.consumptionsList.some(c => c.locationId === l.id));

        this.selectedLocation = this.locationsList[0].id;
        this.selectedLocationName = this.locationsList[0].name;

        this.filterControllers();
        this.currentDate = moment().format('DD/MM/YYYY, h:mm a');
      },
        error => {
          this.showError = true;
        }
      )
  }

  filterControllers() {
    this.filteredEntities = [];

    let filteredConsumptionList = this.consumptionsList.filter(c =>
      (!this.selectedLocation || c.locationId === this.selectedLocation));

    let filteredControllersList = this.controllersList.filter(c =>
      (!this.selectedLocation || c.locationId === this.selectedLocation));

    filteredConsumptionList.forEach(c => this.filteredEntities.push(c));
    filteredControllersList.forEach(c => this.filteredEntities.push(c));

    this.selectedEntity = this.filteredEntities[0];

    if (this.selectedEntity) {
      this.isConsumption = this.consumptionsList.some(c => c.id === this.selectedEntity.id);

      this.loadData();
    }
  }

  loadData() {
    this.showError = false;
    this.spinnerShow = true;

    timer(0, 60 * 1000).pipe(
      switchMap(() =>
        iif(() => this.isConsumption,
          forkJoin([
            this.consumptionService.getLatest(this.selectedEntity.id),
            this.consumptionMeasurementsService.getFlowRate(this.selectedEntity.id)
          ]),          
          forkJoin([
            this.measurementsService.getLatest(this.selectedEntity.id),
            this.measurementsService.getFlowRate(this.selectedEntity.id)
          ]) 
          )  
      )
      ).subscribe(([pieChartData, timeChartData]) => {      
        this.oxygenMeasure = pieChartData;
        this.calcDialPercentValue();
        this.flowRate = timeChartData;

        this.spinnerShow = false;
      })
  }

  calcDialPercentValue() {
    if (!this.oxygenMeasure) return;

    if (this.isConsumption) {
      this.currentFlowRate = this.oxygenMeasure.u1;
      this.reserveFlowRate = this.oxygenMeasure.w / 60;
      this.skidDrCapacity = this.oxygenMeasure.t / 60;
    }
    else {
      this.currentFlowRate = this.oxygenMeasure.u1;
      this.reserveFlowRate = this.oxygenMeasure.w1;
      this.skidDrCapacity = this.oxygenMeasure.t1;
    }

    let t = this.oxygenMeasure.t;
    if (!this.isConsumption) t = this.oxygenMeasure.t1;

    // if (t == 0) {
    //   this.dialPercentValue = 0;
    // }
    // else {
    //   this.dialPercentValue = (this.oxygenMeasure.u1 * 100 / t);
    // }
  }

  selectLocationChange() {
    this.filterControllers();

    let res = this.locationsList.filter(l => {
      if (l.id == this.selectedLocation) {
        return l
      }
    })
    this.selectedLocationName = res[0].name;
  }

  selectControllerChange() {
    this.loadData();
  }

}

