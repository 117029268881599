import { Sensor } from './sensor';
import { RecognitionBox } from './recognition-box';
import { Entity } from './entity.interface';

export class Controller implements Entity {
    id: string;
    name: string;
    macAddress: string;
    companyId: string;    
    locationId:  string;
    areaId: string;
    groupId: string;
    configUrl: string;
    webcamUrl: string;    
    manufactureType: number;
    controllerType: number;
    providerId: string;
    sensors: Sensor[];
    
    tankCapacity: number;
    tankFillRate: number;
    liquidToGasMulytiplier : number;
    skidDesignFlow: number;
    skidDerating: number;
    maxSiteNetworkDlowrate: number;
    tankPreasureBarOptimal: number;

    iceDerating: number;
    iceDeratingPercent: number;
    
    iceDerating0: number;
    iceDerating25: number;
    iceDerating50: number;
    iceDerating75: number;
    iceDerating100: number;

    webcamTitle: string;
    webcam2Title: string;
    webcam3Title: string;
    webcamUrl2: string;
    webcamUrl3: string;    
}