import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location as _Location } from '@angular/common';
import { ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private api: string = ENV.settings.apiUrl + '/api/users';

  constructor(
    private httpClient: HttpClient,
    private _location: _Location,
  ) { }

  setPassword(password: string, code: string): Observable<any> {
    const body = {
      password,
      code
    };
    return this.httpClient.post(`${this.api}/set-password`, body);
  }

  requestPasswordReset(email: string): Observable<any> {
    const urlTemplate = window.location.origin + this._location.prepareExternalUrl('/reset-password?code={code}');
    return this.httpClient.post(`${this.api}/request-password-reset`, { email, urlTemplate });
  }
}
