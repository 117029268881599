import { Injectable } from '@angular/core';
//import { Messenger } from '../_scripts/messages/messenger';
//import * as axios from 'axios';
import { StorageService } from './storage.service';
import { ServerResponse } from './../_models/server-response';
//import { ServerMessage } from '../_scripts/messages/server-message';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class AuthenticatedService {
   // private messanger = new Messenger();

    constructor(
        protected _http: HttpClient,
    ) { }

    getObservable (url: string, data: any = null) {
        
        let parameters = {};        
        if(data)
            parameters["params"] = data;
            
        return this._http.get(url, parameters).pipe(
                map((response:ServerResponse) => {
                    // let messages = response.messages;
                    // this.messanger.showAll(messages);
                    return response.data;
                }),
                catchError(err => {
                    // this.messanger.showConnectionError();
                    alert("System is on the maintenance. Please, wait and then refresh the page");
                    
                    return throwError(err);
                })
            )
    }


    // private handleError<T>(operation = 'operation', result?: T) {
    //     return (error: any): Observable<T> => {
    //       console.error(error);
    //       //this.log(`${operation} failed: ${error.message}`);
      
    //       return of(result as T);
    //     };
    //   }

      private handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // this.messanger.showConnectionError();
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}