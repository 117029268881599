import { Injectable } from '@angular/core';
import { AuthenticatedService } from './authenticated.service';
import { Observable } from 'rxjs';
import { Consumption } from '../_models/consumption';
import { OxygenMeasure } from '../_models/oxygenMeasure';



@Injectable()
export class ConsumptionService {
    private api: string = '/api/o2Consumption';
    
    constructor(
        private authenticatedService: AuthenticatedService
    ) { }


    listAsync():Observable<Consumption[]> {
        return this.authenticatedService.getObservable(this.api)    
    }


    getLatest(consumptionId: string):Observable<OxygenMeasure> {  

        let url = `${this.api}/${consumptionId}/last`;
        
        return this.authenticatedService.getObservable(url);
    }

}
