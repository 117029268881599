import { Injectable } from '@angular/core';
import { AuthenticatedService } from './authenticated.service';
import { Location } from '../_models/location';
import { Observable } from 'rxjs';


@Injectable()
export class LocationsService {
    private api: string = '/api/locations';

    constructor(
        private authenticatedService: AuthenticatedService
    ) { }

    listAsync(): Observable<Location[]> {
        return this.authenticatedService.getObservable(this.api)
    }

}
