import { Injectable } from '@angular/core';
import { AuthenticatedService } from './authenticated.service';
import { OnControllerList, OnControllerGet, OnControllerAdded, OnControllerUpdated, OnControllerDeleted, OnControllerUploaded } from '../_models/controller.interface';
import { Controller as Controller } from '../_models/controller';
import { Observable } from 'rxjs';



@Injectable()
export class ControllerService {
    
    private api: string = '/api/controllers';

    constructor(
        private authenticatedService: AuthenticatedService
    ) { }

    listAsync():Observable<Controller[]> {
        return this.authenticatedService.getObservable(this.api)    
    } 
}
