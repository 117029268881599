<div class="spinner_wrapper" *ngIf = "spinnerShow" >
    <div class="cm-spinner" ></div>
</div>

<div class="main">
    <div class="header">
        <a href="http://thermtec.ie">
            <img src="/assets/images/hse_logo.png" alt="hse_logo" width="157px" height="122px" />
        </a>
    </div>

    <div class="selects">
        <label>Location</label>
        <select [(ngModel)]='selectedLocation' (change)="selectLocationChange()">
            <option></option>
            <option [ngValue]="location.id" *ngFor="let location of locationsList; let selectedIndex = index;">
                {{location.name}}
            </option>
        </select>
        <br>
        <label>Controller</label>
        <select [(ngModel)]='selectedEntity' (change)="selectControllerChange()">
            <option [ngValue]="controller" *ngFor="let controller of filteredEntities; let i = index">
                {{controller.name}}
            </option>
        </select>
    </div>

    <h2 *ngIf="selectedLocationName">{{selectedLocationName}}</h2>   
    <div class="error_message" *ngIf="oxygenMeasure && !oxygenMeasure.isActual" style="min-height: 300px;"> 
        Poor Data Connection <br>
        Contact Help Desk
        
    </div>   

    <div *ngIf="oxygenMeasure && oxygenMeasure.isActual"  class= "gauge_wrapper" style="position: relative;  height:400px; width: 100%; margin-top: -7px; border:0px solid red">
        <div class="scrollable gauge"></div>
        <div *ngIf="showError" class="error_message">           
           <div>Data Load Error</div>
        </div>
        <app-gauge-chart 
            *ngIf="selectedEntity && oxygenMeasure && oxygenMeasure.isActual"
            [selectedEntity]="selectedEntity" 
            [selectedLocationName]="selectedLocationName"
            [oxygenMeasure]="oxygenMeasure">
        </app-gauge-chart>
        <span><b>Current % Demand on Oxygen System</b></span>
    </div>
<br><br>
    <div class="current_time">
        {{currentDate}}
    </div> 

    <div *ngIf="oxygenMeasure && oxygenMeasure.isActual" class="rectangle_wrapper"> 
        <div class="rectangle rectangle_blue">
            <span>Supply Hours Remaining At Current Flow Rate </span> 
            <b>{{(oxygenMeasure.h/oxygenMeasure.u) | number:'1.0-0'}}</b>
        </div>      
        <div class="rectangle rectangle_seawave">
            <span>Maximum System Capacity (L/min) (a) </span> 
            <b>{{skidDrCapacity | number:'1.0-0'}} </b>
        </div>
        <div class="rectangle rectangle_green">
            <span>Current System Capacity Used (L/min) (b) </span> 
            <b>{{currentFlowRate | number:'1.0-0'}} </b>
        </div>
        <div class="rectangle rectangle_blue">
            <span>Remaining System Capacity (L/min) (a-b) </span> 
            <b>{{reserveFlowRate | number:'1.0-0'}} </b>
        </div>
    </div>
    <p>© Designed by EMTel.ie</p>
</div>

