import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const anonymousRequests = ["api/status", "api/init", "api/auth", "api/users/set-password", "api/users/request-password-reset"];

    if (!(anonymousRequests.some(x => req.url.includes(x)))) {
      let session = this.storageService.getSession();

      let header: HttpHeaders;

      if (session.companyId)
        header = new HttpHeaders({
          'token': session.token,
          'companyId': session.companyId
        })
      else
        header = new HttpHeaders({
          'token': session.token
        });

      let request = req.clone({
        headers: header
      });
      return next.handle(request);
    }

    return next.handle(req);
  }
}