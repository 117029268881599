import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { ENV } from '../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!req.url.startsWith("http"))
    {
      let ulrStr = req.url;
      if(!ulrStr.startsWith("/"))
      ulrStr = "/" + ulrStr;

      const apiReq = req.clone({ url: ENV.settings.apiUrl+ulrStr });
      return next.handle(apiReq);
    }
    else
      return next.handle(req);
  }
}


