import { Component, OnInit, Input } from '@angular/core';
import { OxygenMeasure } from '../../_models/oxygenMeasure';
import { Entity } from 'src/app/_models/entity.interface';

const data = {
  chart: {
    caption: "Hospital Name",    
    lowerlimit: "0",
    upperlimit: "100",
    showvalue: "1",
    valuefontsize: "30",
    numbersuffix: "%",
    theme: "fusion",
    showtooltip: "0",
    adjustTM: "0",
    majorTMNumber: "11",
    minorTMNumber: "9",
    creditLabel: false,
    // chartTopMargin: "100",
    chartBottomMargin: "80",
    chartLeftMargin: "-5",
    chartRightMargin: "-1",

  },
  colorrange: {
    color: [
      {
        minvalue: "0",
        maxvalue: "69",
        code: "#F2726F",
        color: "#66CC00"  //green
      },
      {
        minvalue: "70",
        maxvalue: "89",
        code: "#FFC533",
        color: "#FFb600" //yellow
      },
      {
        minvalue: "90",
        maxvalue: "100",
        code: "#FF6347",
        color: "#FF6347"  //red
      }
    ]
  },
  dials: {
    dial: [
      {
        value: "81"
      }
    ]
  }
};

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {

  @Input() selectedEntity: Entity;
  //@Input() selectedControllerName: string;
  @Input() selectedLocationName: string;
  @Input() oxygenMeasure: OxygenMeasure;

  dialPercentValue:string;

  width = '100%';
  height =  '90%';
  type = "angulargauge";
  dataFormat = "json";
  dataSource = data;

  constructor() { }

  ngOnInit() { 
    this.parseData()    
  }

  ngOnChanges() {
    if (this.selectedEntity) {     
      this.parseData()      
    }
  }

  parseData() {

    this.dataSource.chart.caption = '';
    if (this.oxygenMeasure) {
      //console.log(this.oxygenMeasure);
     
      this.calcDialPercentValue()
      this.dataSource.dials.dial[0].value = this.dialPercentValue;
    }
  }

  calcDialPercentValue(){
    this.dialPercentValue = Math.round(this.oxygenMeasure.u1*100/(this.oxygenMeasure.t/60)).toString();
  }

}
