<div class="header">
    <a href="http://thermtec.ie">
        <img src="/assets/images/hse_logo.png" />
    </a>
</div>

<div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

        <div>
            <label for="email">Email</label>
            <div>
                <input type="email" placeholder="Email" formControlName="email">
            </div>
        </div>

        <div>
            <button type="submit" [disabled]="form.invalid">
                Reset password
            </button>
            &nbsp;
            <button type="button" [routerLink]="['/']" class="btn btn-light btn-cancel">Cancel</button>
        </div>
    </form>
</div>