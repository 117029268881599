
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChartWrapperComponent } from './components/chart-wrapper/chart-wrapper.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LocationsService } from './_common/locations.service';
import { ControllerService } from './_common/controller.service';
import { StorageService } from './_common/storage.service';
import { AuthenticatedService } from './_common/authenticated.service';
import { MeasurementsService } from './_common/measurements.service';
import { FusionChartsModule } from 'angular-fusioncharts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as charts from 'fusioncharts/fusioncharts.charts';

import * as Widgets from 'fusioncharts/fusioncharts.widgets';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { UrlInterceptor } from './_common/url-interceptor';
import { TokenInterceptor } from './_common/token-interceptor';
import { ConsumptionService } from './_common/consumption.service';
import { ClientIdInterceptor } from './_common/clientId-interceptor';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CompareValidatorDirective } from './_common/compare-validator.directive';
import { ConsumptionMeasurementsService } from './_common/consumption-measurements.service';

FusionCharts.options.creditLabel = false;


// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, Widgets, FusionTheme);

@NgModule({
  declarations: [
    AppComponent,
    ChartWrapperComponent,
    SignInComponent,
    NotFoundComponent,    
    GaugeChartComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    CompareValidatorDirective
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FusionChartsModule,
    BrowserAnimationsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ClientIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    StorageService,
    LocationsService,
    ConsumptionService,
    ControllerService,
    AuthenticatedService,
    MeasurementsService,
    ConsumptionMeasurementsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
