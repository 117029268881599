<div class="header">
    <a href="http://thermtec.ie">
        <img src="/assets/images/hse_logo.png" />
    </a>
</div>

<div *ngIf="!code" class="not-valid">
    <p>Not valid code</p>
</div>
<div class="container" *ngIf="code">

    <form [formGroup]="form">
        <div>
            <label>Password</label>
            <div>
                <input type="password" placeholder="Password" formControlName="password" autocomplete="new-password">
                <label class="view-error" *ngIf="invalid('password')">
                    Please enter a password, it must be at least 8 characters
                </label>
            </div>
        </div>

        <div>
            <label>Confirm Password</label>
            <div>
                <input type="password" placeholder="Confirm Password" formControlName="passwordConfirm"
                    compare="password">
                <div *ngIf="invalid('passwordConfirm')">
                    <label class="view-error" *ngIf="hasError('passwordConfirm', 'required')">
                        Please confirm a password
                    </label>
                    <label class="view-error" *ngIf="hasError('passwordConfirm', 'compare')">
                        Password confirm is not match
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div>
                <button type="button" (click)="onSave()" [disabled]="form.invalid">
                    Save Password
                </button>
                &nbsp;
                <button type="button" [routerLink]="['/']">Cancel</button>
                <div *ngIf="isPswSet">
                    <br>
                    Your Password is now set
                    <br>
                    Please login through the URL you were provided
                </div>
            </div>
        </div>

    </form>
</div>