import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { AuthModel } from '../_models/auth-model';
import { AuthResult } from '../_models/auth-result';
import { ENV } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ServerResponse } from '../_models/server-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  getUserDetails(model: AuthModel) {
    return this.http.post(ENV.settings.apiUrl + '/api/auth', model)
      .pipe(
        map((response: ServerResponse) => {
          let res = <AuthResult>response.data;
          if (res.result) {
            res.session.companyId = "5e8585cc1cf13a0001bc85b2";
            this.storageService.setSession(res.session);
          }
          return res;
        })
      );
  }

}
