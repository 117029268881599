import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_common/user.service';

const PASSWORD_REGEX = /^.{8,}$/;

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  public form: FormGroup;
  public code: string;
  isPswSet: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(PASSWORD_REGEX)]),
      passwordConfirm: new FormControl('', [Validators.required])
    });
    this.code = this.activatedRoute.snapshot.queryParams['code'];
  }

  invalid(name: string): boolean {
    let control: AbstractControl = this.form.get(name);
    return control.invalid && (control.dirty || control.touched);
  }

  hasError(name: string, error: string) {
    let control: AbstractControl = this.form.get(name);
    return control.hasError(error) && (control.dirty || control.touched);
  }

  public onSave() {
    if (this.form.invalid) return;

    const password = this.form.controls.password.value;

    this.userService.setPassword(password, this.code)
      .subscribe(
        () => this.isPswSet = true,

        (err: HttpErrorResponse) => {
          const msg = err.status === 400 ? err.error : "Operation failed";
          alert(msg);
        }
      );
  }
}
