import { EnigmaSession } from '../_models/enigma-session';
import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    private Enigma: string = 'Enigma';

    getSession(): EnigmaSession {
        return <EnigmaSession>JSON.parse(localStorage[this.Enigma]);
    }

    setSession(state: EnigmaSession) {
        localStorage[this.Enigma] = JSON.stringify(state);
    }

    clear() {
        localStorage.clear();
    }
}