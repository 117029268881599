import { ENV as defaultEnvironment, ENV } from './environment';

export const environmentLoader = new Promise<any>((resolve, reject) => {

  var xmlhttp = new XMLHttpRequest(),
    method = 'GET',    
      url = './assets/environments/environment.json';

    xmlhttp.open(method, url, true);
    xmlhttp.onload = function() {
      if (xmlhttp.status === 200) {
        resolve(JSON.parse(xmlhttp.responseText));
       // console.log(xmlhttp.responseText);
      } else {
        resolve(defaultEnvironment);
        //console.log(defaultEnvironment);
      }
    };
  xmlhttp.send();
});