<div class="header">
    <a href="http://thermtec.ie">
        <img src="/assets/images/hse_logo.png" />
    </a>
</div>

<div class="signin_container">

    <form (submit)="loginUser($event)">
        <h2 class="">Sign In to your Account</h2>
        <div>
            <label>Enter your user name</label>
            <input type="text" placeholder="user email" id="username">
        </div>
        <div>
            <label>Enter password</label>
            <input type="password" placeholder="password" id="password">
            <br>
            <span>
                <a [routerLink]="['forgot-password']"> Forgot password </a>
            </span>
        </div>

        <div class="remember">
            <input type="checkbox" id="rememberMe" checked>
            <label for="rememberMe">Remember me</label>
        </div>

        <button type="submit">Submit</button>
    </form>

    <br>
    <a href="https://join.hse.emtel.ie/">
        <h3>Registration Click Here</h3>
    </a>
</div>