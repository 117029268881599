import { Injectable } from '@angular/core';
import { AuthenticatedService } from '../_common/authenticated.service';
import { Observable } from 'rxjs';
import { OxygenMeasure } from '../_models/oxygenMeasure';
import { FlowRate } from '../_models/flowRate';

@Injectable()
export class ConsumptionMeasurementsService {
    private api: string = '/api/o2consumptionmeasurements';

    constructor(
        private authenticatedService: AuthenticatedService
    ) { }


    getLatest(consumptionId: string):Observable<OxygenMeasure> {  

        let url = `${this.api}/${consumptionId}/last`;
        
        return this.authenticatedService.getObservable(url);
    }

    getFlowRate(consumptionId: string):Observable<Array<FlowRate>> {  

        let url = `${this.api}/${consumptionId}/flowrate24`;
        
        return this.authenticatedService.getObservable(url);
    }
}

