import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_common/user.service';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
  ) { }

  public ngOnInit() {
    this.form = this.fb.group(
      {
        email: ["", [Validators.required, Validators.pattern(EMAIL_REGEX)]]
      }
    );
  }

  public onSubmit() {
    this.userService.requestPasswordReset(this.form.controls.email.value)
      .subscribe(
        () => {
          alert("Password reset email was sent");
          this.router.navigate(["/"]);
        },
        (err: HttpErrorResponse) => {
          const msg = [400, 401, 404].includes(err.status) ? err.error : "Operation failed";
          alert(msg);
        })
  }
}
